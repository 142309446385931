
















































































































































import useMisc from "@/use/misc";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import {
  AdditionalServiceDto,
  AdditionalServiceGroupDto,
} from "@/types/additionalService";

export default defineComponent({
  components: {
    MReserveList: () =>
      import("@/components/molecules/additionalServices/m-reserve-list.vue"),
  },
  setup(_, { root }) {
    const state = reactive({
      headers: [
        {
          value: "index",
          sortable: false,
          text: root.$tc("layout.misc.orderNumber"),
          width: 1,
        },
        {
          value: "name",
          sortable: false,
          text: root.$tc("layout.misc.groupName"),
        },
        {
          value: "formType",
          sortable: false,
          text: root.$tc("layout.misc.type"),
        },
        {
          value: "sendEmail",
          sortable: false,
          text: root.$tc("layout.misc.sendRequest"),
        },
        {
          value: "limit",
          text: root.$tc("layout.misc.maxQuantity"),
          sortable: false,
          width: 1,
          align: "center",
        },
        {
          value: "isEnabled",
          text: root.$tc("layout.misc.state"),
          sortable: false,
          width: 1,
          align: "right",
        },
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "right",
          width: 1,
        },
      ],
      items: [],
      loading: false,
      loaded: false,
      total: 0,
      currency: "",
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
    });

    const { getDiscountType, getChoiceType } = useMisc({ root });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const { sortBy, sortDesc } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/additional-service-group/list`, {
          params: { sortBy, sortDesc },
        })
        .then(({ data: { additionalServiceGroups, total } }) => {
          state.items = additionalServiceGroups
            .map((item: any) => ({
              additionalServices: item.additionalServices.sort(
                (a: any, b: any) => a.ordinalNumber - b.ordinalNumber
              ),
              ...item,
            }))
            .sort((a: any, b: any) => a.ordinalNumber - b.ordinalNumber);
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/basic`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const deleteGroup = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`/additional-service-group/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    const deleteService = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`/additional-service/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    const changeAdditionalServiceStatus = (
      item: AdditionalServiceGroupDto,
      service: AdditionalServiceDto
    ) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      state.loading = true;

      axiosInstance
        .get(`additional-service/${service.id}`)
        .then(({ data }) => {
          const serviceData = {
            name: data.name,
            isEnabled: !data.isEnabled,
            type: data.type,
            price: data.price,
            amount: data.amount,
            additionalInformation: data.additionalInformation || undefined,
            participantGroupIds: data.participantGroups
              ? data.participantGroups.map((group: any) => group.id)
              : [],
            discountIds: data.discounts
              ? data.discounts.map((discount: any) => discount.id)
              : [],
            additionalServiceGroupId: item.id,
          };

          axiosInstance
            .put(`/additional-service/${service.id}`, serviceData, {
              headers: {
                event: root.$route.params.id,
              },
            })
            .then(() => {
              fetchData();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              state.loading = false;
            });
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    const changeAdditionalServiceGroupStatus = (
      item: AdditionalServiceGroupDto
    ) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      state.loading = true;

      const data = {
        name: item.name,
        isEnabled: !item.isEnabled,
        type: item.type,
        formType: item.formType,
        limit: item.limit,
        isRequired: item.isRequired,
        includeGroup: item.includeGroup,
        sendEmail: item.sendEmail,
        email: item.email || undefined,
      };

      axiosInstance
        .put(`/additional-service-group/${item.id}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          fetchData();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const copyAdditionalService = (
      item: AdditionalServiceGroupDto,
      service: AdditionalServiceDto
    ) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      const name = prompt(
        root.$tc("event.panel.modules.additionalServiceModule.newPolishName"),
        `${service.name} (kopia)`
      );

      const englishName = prompt(
        root.$tc("event.panel.modules.additionalServiceModule.newEnglishName"),
        `${service.englishName} (kopia)`
      );

      const data = {
        name,
        englishName,
        isEnabled: service.isEnabled,
        type: service.type,
        price: service.price,
        amount: service.amount,
        additionalInformation: service.additionalInformation || undefined,
        participantGroupIds: service.participantGroups.map((group) => group.id),
        discountIds: service.discounts.map((discount) => discount.id),
        additionalServiceGroupId: item.id,
      };
      if (name && englishName) {
        axiosInstance
          .post(`/event/${root.$route.params.id}/additional-service`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            fetchData();
          })
          .catch((error) => {
            console.log(error);
            alert(
              root.$tc(
                "event.panel.modules.additionalServiceModule.additionalService.errorCopyAdditionalService"
              )
            );
          })
          .finally(() => {
            state.loading = false;
          });
      } else {
        state.loading = false;
      }
    };
    const copyAdditionalServiceGroup = (item: AdditionalServiceGroupDto) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      const name = prompt(
        root.$tc(
          "event.panel.modules.additionalServiceModule.newPolishGroupName"
        ),
        `${item.name} (kopia)`
      );
      const englishName = prompt(
        root.$tc(
          "event.panel.modules.additionalServiceModule.newEnglishGroupName"
        ),
        `${item.englishName} (kopia)`
      );

      const data = {
        name,
        englishName,
        isEnabled: item.isEnabled,
        type: item.type,
        formType: item.formType,
        limit: item.limit,
        isRequired: item.isRequired,
        includeGroup: item.includeGroup,
        sendEmail: item.sendEmail,
        email: item.email,
      };
      if (name && englishName) {
        axiosInstance
          .post(
            `/event/${root.$route.params.id}/additional-service-group`,
            data,
            {
              headers: {
                event: root.$route.params.id,
              },
            }
          )
          .then(() => {
            fetchData();
          })
          .catch((error) => {
            console.log(error);
            alert(
              root.$tc(
                "event.panel.modules.additionalServiceModule.additionalServiceGroup.errorCopyAdditionalServiceGroup"
              )
            );
          })
          .finally(() => {
            state.loading = false;
          });
      } else {
        state.loading = false;
      }
    };

    return {
      state,
      fetchData,
      deleteGroup,
      deleteService,
      getDiscountType,
      getChoiceType,
      changeAdditionalServiceStatus,
      changeAdditionalServiceGroupStatus,
      copyAdditionalService,
      copyAdditionalServiceGroup,
    };
  },
});
